import React from 'react';

const UploadModal = ({open, title, text, label, info, cancel, save, loading, file, setFile, files, setFiles, type,
  premiumV, setPremiumV,
  levyV, setLevyV,
  transferFeeV, setTransferFeeV,
  hkdM, setHkdM,
  premiumLabel, setPremiumLabel,
  dueDate, setDueDate,
}) => {
  return (
    <>
      {
        open ?
        <div className='modal-overlay'>
          <div className='upload-modal-container'>
            <div className='upload-modal-body'>
              <div className='upload-modal-body-text-wrap'>
                <div className='upload-modal-body-title'>Please upload the file, then click 'Save'.</div>
                <div className='upload-modal-body-text'>Once you click 'Save', you will proceed to the next step and <span className='bold underline'>will not be able to change the file.</span> Please be careful.</div>
              </div>
              <div className='upload-input-valid-wrap'>
                {
                  label === 'Application' || type === 'invoice' ? (
                    <div className='upload-input-wrap'>
                      <div className='input-list-wrap'>
                        <div className='upload-input-label'>Invoice Info (USD)</div>
                        <div className='upload-input-info'>· Please enter only if an invoice has been requested.</div>
                        <div style={{height: '16px'}} />
                        <div className='upload-modal-input-wrap'>
                          <div className='upload-modal-input-label'>Premium Label</div>
                          <input className='text-input fill' value={premiumLabel} onChange={({target: {value}}) => setPremiumLabel(value)} placeholder='Swiss Fortune - 5 Pay' />
                        </div>
                        <div className='upload-modal-input-wrap'>
                          <div className='upload-modal-input-label'>Premium</div>
                          <input className='text-input fill' value={premiumV} onChange={({target: {value}}) => setPremiumV(value)} />
                        </div>
                        <div className='upload-modal-input-wrap'>
                          <div className='upload-modal-input-label'>Levy</div>
                          <input className='text-input fill' value={levyV} onChange={({target: {value}}) => setLevyV(value)} />
                        </div>
                        <div className='upload-modal-input-wrap'>
                          <div className='upload-modal-input-label'>Transfer Fee</div>
                          <input className='text-input fill' value={transferFeeV} onChange={({target: {value}}) => setTransferFeeV(value)} placeholder='40' />
                        </div>
                        <div className='upload-modal-input-wrap'>
                          <div className='upload-modal-input-label'>HKD/USD</div>
                          <input className='text-input fill' value={hkdM} onChange={({target: {value}}) => setHkdM(value)} placeholder='7.8' />
                        </div>
                        <div className='upload-modal-input-wrap'>
                          <div className='upload-modal-input-label'>Due Date</div>
                          <input className='text-input fill' value={dueDate} onChange={({target: {value}}) => setDueDate(value)} maxLength={8} placeholder='20250101' />
                        </div>
                      </div>
                    </div>
                  ) : null
                }
                {
                  type === 'invoice' ? null : (
                    <div className='upload-input-wrap'>
                      <div className='upload-input-text-wrap'>
                        <div className='upload-input-label'>{label}</div>
                        <div className='upload-input-info'>{info}</div>
                      </div>
                      {
                        file !== null ? (
                          <div className='upload-input-file'>
                            <img src={'/icon/upload-document.png'} alt ='document' />
                            <div className='upload-input-file-name'>{file.name}</div>
                          </div>
                        ) : null
                      }
                      {
                        files && files.length > 0 ? (
                          files.map((file, i) => {
                            return (<div className='upload-input-file' key={String(i)}>
                              <img src={'/icon/upload-document.png'} alt ='document' />
                                <div className='upload-input-file-name'>{file.name}</div>
                                <img src="/icon/close.png" alt="close" style={{cursor: 'pointer'}} onClick={() => {
                                  const fs = [...files];
                                  fs.splice(i, 1);
                                  setFiles(fs);
                                }} />
                              </div>);
                          })
                        ) : null 
                      }
                      <div className='upload-input-btn' onClick={() => document.getElementById(type === 'application' ? 'files' : 'file').click()}>
                        <input type="file" multiple style={{display: 'none'}} id="files" onChange={({target: {files: fs}}) => {
                          if (fs.length > 0) {
                            setFiles([...files, ...Array.from(fs)]);
                          }
                        }} />
                        <input type="file" style={{display: 'none'}} id="file" onChange={({target: {files}}) => {
                          if (files.length > 0) {
                            setFile(files[0]);
                          }
                        }} />
                        <img src={'/icon/upload-upload.png'} alt ='upload' />
                        <div className='upload-input-btn-name'>Upload {`${type === 'application' ? 'Files' : 'File'}`}</div>
                      </div>
                    </div>
                  )
                }
                {/* <div className='upload-input-valid'>Please upload a file before proceeding to the next step.</div> */}
              </div>
            </div>
            <div className='upload-modal-foot'>
              {
                loading ? null : <div className='upload-btn-cancel' onClick={() => cancel()}>Cancel</div>
              }
              {
                type === 'invoice' ? (
                  <div className='upload-btn-save' onClick={dueDate.length === 8 && !loading ? () => save() : undefined} style={{opacity: dueDate.length === 8 ? 1 : 0.3}}>
                    {loading ? 'Uploading...' : 'Save'}
                  </div>
                ) :
                type === 'application' ? (
                  <div className='upload-btn-save' onClick={files.length > 0 && !loading ? () => save() : undefined} style={{opacity: files.length > 0 ? 1 : 0.3}}>
                    {loading ? 'Uploading...' : 'Save'}
                  </div>
                ) : (
                <div className='upload-btn-save' onClick={file && !loading ? () => save() : undefined} style={{opacity: file ? 1 : 0.3}}>
                  {loading ? 'Uploading...' : 'Save'}
                </div>
                )
              }
            </div>
          </div>
        </div> : null
      }
    </>
  );
};

export default UploadModal;