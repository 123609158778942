import { useEffect, useState } from "react";

import initFirebase from './utils/firebase/init_sdk';

import {getApp} from 'firebase/app';
import {getAuth, signOut} from 'firebase/auth';
import {getFirestore, getDocs, collection} from 'firebase/firestore';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./pages/login/login_page";

import './common.css';
import SideNav from "./components/nav/side_nav";
import TaskLists from "./pages/task/task_lists";
import ConsultantList from "./pages/consultant/consultant_list";
import VisitSchedule from "./pages/schedule/visit_scheduler";

const App = () => {
  const [initLoading, setInitLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMaster, setIsMaster] = useState(false);

  useEffect(() => {
    initFirebase();

    const app = getApp();
    const auth = getAuth(app);

    auth.onAuthStateChanged(async (user) => {
      if (user === null) {
        setIsLoggedIn(false);
        setInitLoading(false);
      } else {
        const {claims} = await user.getIdTokenResult();

        if (claims.authType === 'admin') {
          setIsLoggedIn(true);
          setIsMaster(!user.email.includes('@insurein.life'));
        } else {
          signOut(auth);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      initData();
    }
  }, [isLoggedIn]);

  const initData = async () => {
    const db = getFirestore(getApp());
    const [companies, products] = await Promise.all([
      getDocs(collection(db, 'insurance_companies')),
      getDocs(collection(db, 'insurance_products')),
    ]);

    const comObj = {};
    const proObj = {};
    companies.docs.forEach((doc) => {
      comObj[doc.id] = doc.data();
    });
    products.docs.forEach((doc) => {
      proObj[doc.id] = doc.data();
    });


    window.insurein = {
      companies: comObj,
      products: proObj,
    };

    setInitLoading(false);
  };

  if (initLoading) {
    return (
      <div />
    );
  }

  if (!isLoggedIn) {
    return <LoginPage />;
  }

  if (!isMaster) {
    return (
      <BrowserRouter>
        <SideNav isMaster={false} />
        <div className="content-section">
          <Routes>
            <Route path="/" element={<TaskLists />} />
            <Route path="/tasks" element={<TaskLists />} />
            {/* <Route path="/consultants" element={<ConsultantList />} /> */}
            <Route path="*" element={<div className="empty-404">404</div>} />
          </Routes>
        </div>
      </BrowserRouter>
  );
  }

  return (
      <BrowserRouter>
        <SideNav isMaster={true} />
        <div className="content-section">
          <Routes>
            <Route path="/" element={<TaskLists />} />
            <Route path="/tasks" element={<TaskLists />} />
            <Route path="/consultants" element={<ConsultantList />} />
            <Route path="/visit_schedule" element={<VisitSchedule />} />
            <Route path="*" element={<div className="empty-404">404</div>} />
          </Routes>
        </div>
      </BrowserRouter>
  );
};

export default App;
