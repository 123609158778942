import { getApp } from "firebase/app";
import { doc, getFirestore, updateDoc, arrayUnion } from "firebase/firestore";

const uploadInvoice = async (caseNo, invoiceInfo) => {
  await updateDoc(doc(getFirestore(getApp()), 'insurance_cases', caseNo), {
    invoices: arrayUnion({
      case_opened: true,
      case_submitted: false,
      created_at: new Date().getTime(),
      files: [],
      levy: {
        usd: Number(invoiceInfo.levyV),
        hkd: Number(invoiceInfo.levyV) * Number(invoiceInfo.hkdM)
      },
      premium: {
        exp: invoiceInfo.premiumLabel,
        usd: Number(invoiceInfo.premiumV),
        hkd: Number(invoiceInfo.premiumV) * Number(invoiceInfo.hkdM)
      },
      transfer_fee: {
        usd: Number(invoiceInfo.transferFeeV),
        hkd: Number(invoiceInfo.transferFeeV) * Number(invoiceInfo.hkdM)
      },
      due_date: invoiceInfo.dueDate,
    }),
  });
};

export default uploadInvoice;
