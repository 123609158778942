import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UploadModal from '../../components/modal/upload_modal';
import ActionRequired from '../../components/action/action_required';
import StateModal from '../../components/modal/state_modal';
import TaskDetailComp from '../../components/task_detail/task_detail';
import GradeModal from '../../components/modal/grade_modal';
import Toast from '../../components/common/toast';
import getListStepLabel from "../../utils/step/get_list_step_label";

import checkProposalData from '../../utils/vertax/check_proposal_data';
import uploadFiles from '../../utils/firebase_storage/upload_files';
import uploadProposal from '../../utils/firebase_firestore/upload_proposal';
import uploadApplication from '../../utils/firebase_firestore/upload_application';
import updateReviewSubstate from '../../utils/firebase_firestore/update_review_substate';
import uploadInvoice from '../../utils/firebase_firestore/upload_invoice';

const TaskDetail = ({caseData, updateReq}) => {
  const navigate = useNavigate();

  // toast controll
  const [toast, setToast] = useState('');

  const [bodyTab, setBodyTab] = useState(2);

  const [uploadModal, setUploaddModal] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [files, setFiles] = useState([]);

  const [premiumLabel, setPremiumLabel] = useState('');
  const [premiumV, setPremiumV] = useState('');
  const [levyV, setLevyV] = useState('');
  const [transferFeeV, setTransferFeeV] = useState('');
  const [hkdM, setHkdM] = useState('');
  const [dueDate, setDueDate] = useState('');

  useEffect(() => {
    setFile(null);
    setFiles([]);
    if (document.getElementById('file')) {
      document.getElementById('file').value = null;
    }
    if (document.getElementById('files')) {
      document.getElementById('files').value = null;
    }
  }, [uploadModal]);
  const openUploadModal = () => {
    setUploaddModal(true);
  };
  const uploadModalCancel = () => {
    setUploaddModal(false);
  };
  const uploadModalSave = async () => {
    try {
      setUploadLoading(true);

      if (caseData.step_info.step === 1) {
        const accountValueTable = await checkProposalData(caseData.product_code, file);

        // return;

        const fs = await uploadFiles(caseData.case_no, [file]);
        
        if (caseData['proposal_request_info']['withdraw_info']['plans'].length === 0) {
          await uploadProposal(caseData.case_no, fs, [accountValueTable[0], null]);
        } else {
          await uploadProposal(caseData.case_no, fs, accountValueTable);
        }
      } else if (caseData.step_info.step === 2) {
        const fs = await uploadFiles(caseData.case_no, files);
        await uploadApplication(caseData.case_no, fs, {
          premiumV,
          levyV,
          hkdM,
          transferFeeV,
          premiumLabel,
          dueDate,
        });

        setPremiumV('');
        setLevyV('');
        setHkdM('');
        setTransferFeeV('');
        setPremiumLabel('');
        setDueDate('');
      } else if (caseData.step_info.step === 4 && dueDate.length === 8) {
        await uploadInvoice(caseData.case_no, {
          premiumV,
          levyV,
          hkdM,
          transferFeeV,
          premiumLabel,
          dueDate,
        });

        setPremiumV('');
        setLevyV('');
        setHkdM('');
        setTransferFeeV('');
        setPremiumLabel('');
        setDueDate('');
      }

      await updateReq(caseData.case_no);

      setUploadLoading(false);
      setUploaddModal(false);
      setToast('uploadSave');
    } catch (err) {
      console.log(err);
      setUploadLoading(false);
    }
  };

  const [stateModal, setStateModal] = useState(false);
  const [selectedState, setSelectedState] = useState(1);
  const openStateModal = () => {
    setStateModal(true);
  };
  const stateModalCancel = () => {
    setStateModal(false);
  };
  const stateModalSave = async () => {
    let substep = '';
    if (selectedState === 1) {
      substep = 'SEND_APPLICATION_TO_IFA';
    } else if (selectedState === 2) {
      substep = 'IFA_ACCEPT';
    } else if (selectedState === 3) {
      substep = 'TC_ACCEPT';
    } else if (selectedState === 4) {
      substep = 'IC_REVIEW';
    } else if (selectedState === 5) {
       
    } else if (selectedState === 6) {
      substep = 'IC_APPROVE';
    } else if (selectedState === 7) {
      substep = 'IC_IP_ISSUE';
    } else if (selectedState === 8) {
      substep = 'IFA_IP_SEND';
    }

    await updateReviewSubstate(caseData.case_no, 3, substep, {});

    await updateReq(caseData.case_no);

    setStateModal(false);
    setToast('stateSave');
  };

  const [gradeModal, setGradeModal] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState(1);
  const openGradeModal = () => {
    setGradeModal(true);
  };
  const gradeModalCancel = () => {
    setGradeModal(false);
  };
  const gradeModalSave = () => {
    setGradeModal(false);
    setToast('gradeSave');
  };

  const productInfo = window.insurein ? window.insurein.products[caseData.product_code] : null;
  const companyInfo = window.insurein ? window.insurein.companies[productInfo.company_code] : null;

  const premium = ((caseData['application_request_info'] && caseData['application_request_info']['basic_info']) ? caseData['application_request_info']['basic_info']['premium_yearly'] : caseData['proposal_request_info']['premium_yearly']).toLocaleString();
  const term = (caseData['application_request_info'] && caseData['application_request_info']['basic_info']) ? caseData['application_request_info']['basic_info']['premium_term'] : caseData['proposal_request_info']['premium_term'];
  const name = (caseData['application_request_info'] && caseData['application_request_info']['contractor_info']) ? caseData['application_request_info']['contractor_info']['name'] : caseData['proposal_request_info'].name;

  return (
    <div className="taskdetail-container">
      <Toast
        text={'The file has been successfully uploaded.'}
        start={toast}
        setStart={setToast}
        toastName="uploadSave"
      />
      <Toast
        text={'The state has been successfully updated.'}
        start={toast}
        setStart={setToast}
        toastName="stateSave"
      />
      <Toast
        text={'The grade has been successfully updated.'}
        start={toast}
        setStart={setToast}
        toastName="gradeSave"
      />
      {
        caseData['step_info']['sub_step'] === 'REQ_PROPOSAL' ? (
          <UploadModal
            title={`Please upload the file, then click 'Save'.`}
            text={<span>Once you click 'Save', you will proceed to the next step and <span className='bold underline'>will not be able to change the file.</span> Please be careful.</span>}
            label={`Proposal`}
            info={`· Please upload the Proposal PDF file.`}
            open={uploadModal}
            cancel={uploadModalCancel}
            save={uploadModalSave}
            loading={uploadLoading}
            file={file}
            setFile={setFile}
          />
        ) : caseData['step_info']['step'] === 4 ? (
          <UploadModal
            type="invoice"
            title={`Please upload the file, then click 'Save'.`}
            text={<span>Once you click 'Save', you will proceed to the next step and <span className='bold underline'>will not be able to change the file.</span> Please be careful.</span>}
            label={`Application`}
            info={`· Please upload the Application PDF file.`}
            open={uploadModal}
            cancel={uploadModalCancel}
            save={uploadModalSave}
            loading={uploadLoading}
            file={file}
            setFile={setFile}
            files={files}
            setFiles={setFiles}
            premiumV={premiumV}
            setPremiumV={setPremiumV}
            levyV={levyV}
            setLevyV={setLevyV}
            transferFeeV={transferFeeV}
            setTransferFeeV={setTransferFeeV}
            hkdM={hkdM}
            setHkdM={setHkdM}
            premiumLabel={premiumLabel}
            setPremiumLabel={setPremiumLabel}
            dueDate={dueDate}
            setDueDate={setDueDate}
          />
        )  :  (
          <UploadModal
            type="application"
            title={`Please upload the file, then click 'Save'.`}
            text={<span>Once you click 'Save', you will proceed to the next step and <span className='bold underline'>will not be able to change the file.</span> Please be careful.</span>}
            label={`Application`}
            info={`· Please upload the Application PDF file.`}
            open={uploadModal}
            cancel={uploadModalCancel}
            save={uploadModalSave}
            loading={uploadLoading}
            file={file}
            setFile={setFile}
            files={files}
            setFiles={setFiles}
            premiumV={premiumV}
            setPremiumV={setPremiumV}
            levyV={levyV}
            setLevyV={setLevyV}
            transferFeeV={transferFeeV}
            setTransferFeeV={setTransferFeeV}
            hkdM={hkdM}
            setHkdM={setHkdM}
            premiumLabel={premiumLabel}
            setPremiumLabel={setPremiumLabel}
            dueDate={dueDate}
            setDueDate={setDueDate}
          />
        )
      }
      <StateModal
        open={stateModal}
        cancel={stateModalCancel}
        save={stateModalSave}
        selected={selectedState}
        setSelected={setSelectedState}
      />
      <GradeModal
        open={gradeModal}
        cancel={gradeModalCancel}
        save={gradeModalSave}
        selected={selectedGrade}
        setSelected={setSelectedGrade}
      />
      <div className='back-btn' onClick={() => navigate(window.location.pathname + window.location.search.split('caseNo=')[0].replace('&', ''), {replace: true})}>
        <img src='/icon/arrow-back.png' alt='back' />
        Back to List
      </div>
      {
        caseData.step_info.step === 1 ? (
          <ActionRequired
            title={`Action Required`}
            text={`You need to upload proposal for user.`}
            btn={{name: 'Upload Proposal', function: () => openUploadModal()}}
          />
        ) : null
      }
      {caseData.step_info.sub_step === 'REQ_APPLICATION' ? (
          <ActionRequired
            title={`Action Required`}
            text={`You need to upload Application for user.`}
            btn={{name: 'Upload Application', function: () => openUploadModal()}}
          />
        ) : null
      }
      {
        caseData.step_info.step === 3 || caseData.step_info.sub_step === 'REC_APPLICATION' ? (
          <ActionRequired
            type='stateChange'
            title={`Action Required`}
            status={getListStepLabel(caseData.step_info.step, caseData.step_info.sub_step)}
            // text={`Hong Gil-dong’s application form has been sent via international mail.\n It takes approximately 5 days (excluding public holidays) to be submitted to the insurance company.`}
            btn={{name: 'Status Change', function: () => openStateModal()}}
          />
        ) : null
      }
      <div className='taskdetail-head-container'>
        <div className='taskdetail-head-title-wrap'>
          <img src={`/icon/flag-${caseData.nationality.toLowerCase()}.png`} alt='nationality' />
          <div className='taskdetail-title'>{caseData.case_no}</div>
        </div>
        <div className='taskdetail-table'>
          <div className='taskdetail-table-head-wrap'>
            <div className='taskdetail-table-head th-product'>Product</div>
            <div className='taskdetail-table-head th-premium'>Premium (y)</div>
            <div className='taskdetail-table-head th-duration'>Term</div>
            <div className='taskdetail-table-head th-customer'>Customer</div>
            <div className='taskdetail-table-head th-consultant'>Consultant</div>
            <div className='taskdetail-table-head th-date'>Update Time</div>
            <div className='taskdetail-table-head th-status'>Status</div>
          </div>
          <div className='taskdetail-table-body-wrap'>
            <div className='taskdetail-table-body th-product'>
              <img src={companyInfo.icon_url} alt="company" />
              {productInfo['product_name']}
            </div>
            <div className='taskdetail-table-body th-premium'>{'$' + premium}</div>
            <div className='taskdetail-table-body th-duration'>{term + ' years'}</div>
            <div className='taskdetail-table-body th-customer'>{name}</div>
            <div className='taskdetail-table-body th-consultant'>{caseData['consultant_info']['name']}</div>
            <div className='taskdetail-table-body th-date'>{new Date(caseData['last_updated_at']).toLocaleDateString()}</div>
            <div className='taskdetail-table-body th-status'>{getListStepLabel(caseData.step_info.step, caseData.step_info.sub_step)}</div>
          </div>
        </div>
      </div>
      <div className='taskdetail-body-container'>
        <div className='tab-menu-wrap'>
          {/* <div className={`tab-menu ${bodyTab === 1 ? 'active' : ''}`} onClick={() => setBodyTab(1)}>Policy Overview</div> */}
          <div className={`tab-menu ${bodyTab === 2 ? 'active' : ''}`} onClick={() => setBodyTab(2)}>Proposal</div>
          {
            caseData['step_info']['step'] >= 3 || (caseData['step_info']['step'] === 2 && caseData['step_info']['sub_step'] !== 'REC_PROPOSAL') ? (
              <div className={`tab-menu ${bodyTab === 3 ? 'active' : ''}`} onClick={() => setBodyTab(3)}>Application</div>
            ) : null
          }
          <div className={`tab-menu ${bodyTab === 4 ? 'active' : ''}`} onClick={() => setBodyTab(4)}>Documents</div>
          <div className={`tab-menu ${bodyTab === 5 ? 'active' : ''}`} onClick={() => setBodyTab(5)}>History</div>
          <div className={`tab-menu ${bodyTab === 6 ? 'active' : ''}`} onClick={() => setBodyTab(6)}>Consultant</div>
          <div className={`tab-menu ${bodyTab === 7 ? 'active' : ''}`} onClick={() => setBodyTab(7)}>Invoices</div>
        </div>
        <TaskDetailComp
          caseData={caseData}
          tab={bodyTab}
          openGradeModal={openGradeModal}
          openUploadModal={openUploadModal}
        />
      </div>
    </div>
  );
};

export default TaskDetail;