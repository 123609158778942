import React, { useEffect, useState } from 'react';
import splitPhoneNumber from '../../utils/format/split_phone_number';

import {getFirestore, getDoc, doc} from 'firebase/firestore';
import {getApp} from 'firebase/app';import getListStepLabel from '../../utils/step/get_list_step_label';
import { useNavigate } from 'react-router-dom';

const TaskDetailComp = ({tab, openGradeModal, caseData, openUploadModal}) => {
  return (
    <>
      {
        tab === 1 ?
        <TaskDetailPolicy caseData={caseData} /> :
        tab === 2 ?
        <TaskDetailProposal caseData={caseData} /> :
        tab === 3 ?
        <TaskDetailApplication caseData={caseData} /> :
        tab === 4 ?
        <TaskDetailDocuments caseData={caseData} /> :
        tab === 5 ?
        <TaskDetailHistory caseData={caseData} /> :
        tab === 6 && caseData.consultant_info.id ?
        <TaskDetailConsultant caseData={caseData} openGradeModal={openGradeModal} /> : tab === 7 ?
        <TaskDetailInvoices caseData={caseData} openUploadModal={openUploadModal} /> : null
      }
    </>
  );
};

const TaskDetailInvoices = ({caseData, openUploadModal}) => {
  const invoices = caseData['invoices'] ?? [];

  if (!invoices) {
    return;
  }

  return (
    <div className='detail-info'>
      <div className='detail-info-title between'>
        <div>Invoices Info</div>
        {
          caseData['step_info']['step'] === 4 ? (
            <div className='detail-info-table-btn' onClick={() => openUploadModal(true)}>
              <img src='/icon/add-circle.png' />
              <span>Add New Invoice</span>
            </div>
          ) : null
        }
      </div>
        <div className='taskdetail-table'>
          <div className='taskdetail-table-head-wrap'>
            <div className='invoice-info-table-head th-type'>Type</div>
            <div className='invoice-info-table-head th-premium'>Premium</div>
            <div className='invoice-info-table-head th-levy'>Levy</div>
            <div className='invoice-info-table-head th-transfer'>Transfer Fee</div>
            <div className='invoice-info-table-head th-date'>Due Date</div>
            <div className='invoice-info-table-head th-file'>File</div>
          </div>
          {
            invoices.map((v, i) => {
              return (
                  <div className='taskdetail-table-body-wrap'>
                    <div className='invoice-info-table-body th-type'>{i+1} - Premium ({v.files.length > 0 ? 'Submitted' : 'Not Submitted'})</div>
                    <div className='invoice-info-table-body th-premium'>{`${v.premium.exp} - ${v.premium.usd.toLocaleString()} USD / ${v.premium.hkd.toLocaleString()} HKD`}</div>
                    <div className='invoice-info-table-body th-levy'>{`${v.levy.usd.toLocaleString()} USD / ${v.levy.hkd.toLocaleString()} HKD`}</div>
                    <div className='invoice-info-table-body th-transfer'>{`${v.transfer_fee.usd.toLocaleString()} USD / ${v.transfer_fee.hkd.toLocaleString()} HKD`}</div>
                    <div className='invoice-info-table-body th-date'>{`${v.due_date}`}</div>
                    <div className='invoice-info-table-body th-file'>
                      {
                        v.files.length > 0 ? (
                          <div className='invoice-info-table-body-download' onClick={() => window.open(v.files[0])}>
                            <img src={'/icon/download.png'}  alt="download" />
                            remittance_confirmation
                            <span>
                            {decodeURIComponent(v.files[0]).split('/')[decodeURIComponent(v.files[0]).split('/').length - 1].split('?')[0]}
                            </span>
                          </div>
                        ) : null
                      }
                    </div>
                  </div>
              )
            })
          }
        </div>
        {/* {
          invoices.map((v, i) => {
            return (
              <div className={'detail-info-table-history' + (i === invoices.length - 1 ? ' last' : '')} key={String(i)} >
                <div>
                  <div>{i+1} - Premium ({v.files.length > 0 ? 'Submitted' : 'Not Submitted'})</div>
                  <div style={{height: '20px'}}>
                  </div>
                  <div>{`${v.premium.exp} - ${v.premium.usd.toLocaleString()} USD / ${v.premium.hkd.toLocaleString()} HKD`}</div>
                  <div>{`Levy - ${v.levy.usd.toLocaleString()} USD / ${v.levy.hkd.toLocaleString()} HKD`}</div>
                  <div>{`Transfer Fee - ${v.transfer_fee.usd.toLocaleString()} USD / ${v.transfer_fee.hkd.toLocaleString()} HKD`}</div>
                  <div>{`Due Date - ${v.due_date}`}</div>
                </div>
                {
                  v.files.length > 0 ? (
                    <div className='detail-info-table-body-download' onClick={() => window.open(v.files[0])} style={{padding: '0px', marginTop: '16px'}}>
                      <img src={'/icon/download.png'}  alt="download" />
                      remittance_confirmation
                      <span>
                      {decodeURIComponent(v.files[0]).split('/')[decodeURIComponent(v.files[0]).split('/').length - 1].split('?')[0]}
                      </span>
                    </div>
                  ) : null
                }
              </div>
            );
          })
        } */}
      <div className='detail-info-table-col-half'>
      </div>
    </div>
  );
};

const TaskDetailPolicy = () => {
  return (
    <div className='detail-info'>
      <div className='detail-info-title'>Policy Overview</div>
      <div className='detail-info-policy-wrap'>
        <div className='detail-info-policy'>
          <div className='detail-info-policy-label'>Refundable Amount</div>
          <div className='detail-info-policy-text'>20,000 USD</div>
        </div>
        <div className='detail-info-policy-progress-row'>
          <div className='detail-info-policy-progress-wrap first'>
            <div className='detail-info-policy-progress-label-wrap'>
              <div className='detail-info-policy-progress-label'>Period</div>
              <div className='detail-info-policy-progress-data'>3y 3m / 5y (65%)</div>
            </div>
            <div className='detail-info-policy-progress-bar-bg'>
              <div className='detail-info-policy-progress-bar' style={{width: '70%'}} />
            </div>
          </div>
          <div className='detail-info-policy-progress-wrap'>
            <div className='detail-info-policy-progress-label-wrap'>
              <div className='detail-info-policy-progress-label'>Payment</div>
              <div className='detail-info-policy-progress-data'>40,000 USD / 50,000 USD (80%)</div>
            </div>
            <div className='detail-info-policy-progress-bar-bg'>
              <div className='detail-info-policy-progress-bar' style={{width: '70%'}} />
            </div>
          </div>
        </div>
        <div className='detail-info-policy-data-row'>
          <div className='detail-info-policy-data-wrap'>
            <div className='detail-info-policy-data-label'>Fullfillment</div>
            <div className='detail-info-policy-data'>95%</div>
          </div>
          <div className='detail-info-policy-data-wrap'>
            <div className='detail-info-policy-data-label'>Rate of Return</div>
            <div className='detail-info-policy-data'>5.65%</div>
          </div>
          <div className='detail-info-policy-data-wrap last'>
            <div className='detail-info-policy-data-label'>Withdrawal Availability Date</div>
            <div className='detail-info-policy-data'>28. 1. 31</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TaskDetailProposal = ({caseData}) => {
  const data = caseData['proposal_request_info'];

  if (!data.birth) {
    return;
  }

  return (
    <div className='detail-info'>
      <div className='detail-info-title'>Customer Submitted Information</div>
      <div className='detail-info-tables-row'>
        <div className='detail-info-table'>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Name</div>
            <div className='detail-info-table-body'>{data.name}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Gender</div>
            <div className='detail-info-table-body'>{data.gender === 'male' ? 'Male': 'Female'}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Date of Birth</div>
            <div className='detail-info-table-body'>{data.birth.substring(0, 4) + '-' + data.birth.substring(4, 6) + '-' + data.birth.substring(6, 8)}</div>
          </div>
          <div className='detail-info-table-row last-item'>
            <div className='detail-info-table-head'>Smoking Status</div>
            <div className='detail-info-table-body'>{data.isSmoking ? 'smoker' : 'non-smoker'}</div>
          </div>
        </div>
        <div className='detail-info-table'>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Premium (y)</div>
            <div className='detail-info-table-body'>{data.premium_yearly.toLocaleString()} USD</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Term</div>
            <div className='detail-info-table-body'>{data['premium_term'] + ' years'}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Payment Option</div>
            <div className='detail-info-table-body'>{data.payment_option}</div>
          </div>
          <div className='detail-info-table-row last-item'>
            <div className='detail-info-table-head'>Monthly Paid</div>
            <div className='detail-info-table-body'>{data.use_monthly_paid ? 'Y' : 'N'}</div>
          </div>
        </div>
        {
        data.withdraw_info.plans && (
          <div className='detail-info-table'>
            {
             data.withdraw_info.plans.map((v, i) => {
                return (
                  <div className={'detail-info-table-row' + (i === data.withdraw_info.plans.length - 1 ? ' last-item' : '')}>
                    <div className='detail-info-table-head'>Withdraw Plan {i + 1}</div>
                    <div className='detail-info-table-body'>
                      {v['end'] === 'continue' ?
                      `${v['start']} years ~ (continue) | ${v['value'] === -99999 ? 'Max withdrawal amount' : `${v['value'].toLocaleString()} USD`}` : v['end'] === 'one-off'
                      ? `${v['start']} years (one-off) | ${v['value'].toLocaleString()} USD` :
                      `${v['start']} years ~ ${v['end']} years | ${v['value'].toLocaleString()} USD`}
                    </div>
                  </div>
                )
              })
            }
          </div>
        )
      }  
      </div>
    </div>
  );
};

const TaskDetailApplication = ({caseData}) => {
  const data = caseData['application_request_info'];
  const basicInfo = data['basic_info'];

  if (!data['sender_info']) {
    return null;
  }

  const contractorInfo = data['contractor_info'];
  const beneInfo = data['beneficiary_info'];
  const jobInfo = data['job_info'];
  const assetInfo = data['asset_info'];
  const senderInfo = data['sender_info'];

  const u = caseData.user_id;
  const n = caseData.case_no;

  const dv = (s) => {
    const CryptoJS = require("crypto-js");
    const secretKey = (u+u+u+u+u).substring(0, 32);
    const Iv = (n+n+n+n+n+n).substring(0, 16);

    const cipher = CryptoJS.AES.decrypt(s, CryptoJS.enc.Utf8.parse(secretKey), {
      iv: CryptoJS.enc.Utf8.parse(Iv),
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });

    const aes256DecodeData = cipher.toString(CryptoJS.enc.Utf8);  

    return aes256DecodeData;
  };

  return (
    <div className='detail-info'>
      <div className='detail-info-title'>Customer Submitted Information</div>
        <div className='detail-info-tables-row'>
          <div className='detail-info-table'>
            <div className='detail-info-table-row'>
              <div className='detail-info-table-head'>Premium (y)</div>
              <div className='detail-info-table-body'>{basicInfo.premium_yearly.toLocaleString()} USD</div>
            </div>
            <div className='detail-info-table-row'>
              <div className='detail-info-table-head'>Term</div>
              <div className='detail-info-table-body'>{basicInfo['premium_term'] + ' years'}</div>
            </div>
            <div className='detail-info-table-row'>
              <div className='detail-info-table-head'>Payment Option</div>
              <div className='detail-info-table-body'>{basicInfo.payment_option}</div>
            </div>
            <div className='detail-info-table-row'>
              <div className='detail-info-table-head'>Monthly Paid</div>
              <div className='detail-info-table-body'>{basicInfo.use_monthly_paid ? 'Y' : 'N'}</div>
            </div>
            <div className='detail-info-table-row'>
              <div className='detail-info-table-head'>Policy Delivery Location</div>
              <div className='detail-info-table-body'>{basicInfo.policy_delivery_loc}</div>
            </div>
            <div className='detail-info-table-row'>
              <div className='detail-info-table-head'>Payment Method</div>
              <div className='detail-info-table-body'>{basicInfo.payment_method}</div>
            </div>
            <div className='detail-info-table-row'>
              <div className='detail-info-table-head'>Card Type</div>
              <div className='detail-info-table-body'>{basicInfo.payment_method.includes('card') ? basicInfo.payment_card_type: '-'}</div>
            </div>
            <div className='detail-info-table-row'>
              <div className='detail-info-table-head'>Card No</div>
              <div className='detail-info-table-body'>{dv(basicInfo.payment_card_no)}</div>
            </div>
            <div className='detail-info-table-row'>
              <div className='detail-info-table-head'>Card Expiration</div>
              <div className='detail-info-table-body'>{basicInfo.payment_card_exp_duration}</div>
            </div>
            <div className='detail-info-table-row'>
              <div className='detail-info-table-head'>Card Front Image</div>
              <div className='detail-info-table-body'>
                <div className='detail-info-table-body-download' onClick={() => window.open(basicInfo.card_front_file)} style={{padding: '0px'}}>
                  <img src={'/icon/download.png'}  alt="download" />
                  <span>
                    {decodeURIComponent(basicInfo.card_front_file).split('/')[decodeURIComponent(basicInfo.card_front_file).split('/').length - 1].split('?')[0]}
                  </span>
                </div>
              </div>
            </div>
            <div className='detail-info-table-row'>
              <div className='detail-info-table-head'>Card Back Image</div>
              <div className='detail-info-table-body'>
                <div className='detail-info-table-body-download' onClick={() => window.open(basicInfo.card_back_file)} style={{padding: '0px'}}>
                  <img src={'/icon/download.png'}  alt="download" />
                  <span>
                    {decodeURIComponent(basicInfo.card_back_file).split('/')[decodeURIComponent(basicInfo.card_back_file).split('/').length - 1].split('?')[0]}
                  </span>
                </div>
              </div>
            </div>
            <div className='detail-info-table-row'>
              <div className='detail-info-table-head'>Invoice Request</div>
              <div className='detail-info-table-body'>{basicInfo.invoice_type}</div>
            </div>
            <div className='detail-info-table-row'>
              <div className='detail-info-table-head'>Invoice Extra Request</div>
              <div className='detail-info-table-body'>{basicInfo.invoice_extra}</div>
            </div>
            <div className='detail-info-table-row'>
              <div className='detail-info-table-head'>Sender Name</div>
              <div className='detail-info-table-body'>{senderInfo.name}</div>
            </div>
            <div className='detail-info-table-row'>
              <div className='detail-info-table-head'>Sender Passport No</div>
              <div className='detail-info-table-body'>{dv(senderInfo.passport_no)}</div>
            </div>
            <div className='detail-info-table-row'>
              <div className='detail-info-table-head'>Sender Phone No</div>
              <div className='detail-info-table-body'>{senderInfo.phone_number}</div>
            </div>
            <div className='detail-info-table-row'>
              <div className='detail-info-table-head'>Sender Job</div>
              <div className='detail-info-table-body'>{senderInfo.job}</div>
            </div>
            <div className='detail-info-table-row'>
              <div className='detail-info-table-head'>Sender Monthly Income</div>
              <div className='detail-info-table-body'>{senderInfo.monthly_income.toLocaleString() + ' USD'}</div>
            </div>
            <div className='detail-info-table-row'>
              <div className='detail-info-table-head'>Sender Relation</div>
              <div className='detail-info-table-body'>{senderInfo.relation}</div>
            </div>
            <div className='detail-info-table-row last-item'>
              <div className='detail-info-table-head'>Extra...</div>
              <div className='detail-info-table-body'>{basicInfo.extra_request}</div>
            </div>
          </div>
        <div className='detail-info-table'>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Contractor Name</div>
            <div className='detail-info-table-body'>{contractorInfo.name}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Contractor Birth</div>
            <div className='detail-info-table-body'>{contractorInfo.birth.substring(0, 4) + '-' + contractorInfo.birth.substring(4, 6) + '-' + contractorInfo.birth.substring(6, 8)}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Contractor Gender</div>
            <div className='detail-info-table-body'>{contractorInfo.gender}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Contractor Smoking</div>
            <div className='detail-info-table-body'>{data.is_smoking ? 'smoker' : 'non-smoker'}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Contractor Email</div>
            <div className='detail-info-table-body'>{contractorInfo.email}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Contractor Passport No</div>
            <div className='detail-info-table-body'>{dv(contractorInfo.passport_no)}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Contractor National No</div>
            <div className='detail-info-table-body'>{dv(contractorInfo.nation_no)}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Contractor Marriage Info</div>
            <div className='detail-info-table-body'>{contractorInfo.marriage}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Contractor Dependents No</div>
            <div className='detail-info-table-body'>{contractorInfo.dependents_no}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Highest level of education</div>
            <div className='detail-info-table-body'>{contractorInfo.education}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Contractor Passport</div>
            <div className='detail-info-table-body'>
              <div className='detail-info-table-body-download' onClick={() => window.open(contractorInfo.passport_file)} style={{padding: '0px'}}>
                  <img src={'/icon/download.png'}  alt="download" />
                  <span>
                    {decodeURIComponent(contractorInfo.passport_file).split('/')[decodeURIComponent(contractorInfo.passport_file).split('/').length - 1].split('?')[0]}
                  </span>
                </div>
            </div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Contractor National Doc</div>
            <div className='detail-info-table-body'>
            <div className='detail-info-table-body-download' onClick={() => window.open(contractorInfo.national_doc_file)} style={{padding: '0px'}}>
                  <img src={'/icon/download.png'}  alt="download" />
                  <span>
                  {decodeURIComponent(contractorInfo.national_doc_file).split('/')[decodeURIComponent(contractorInfo.national_doc_file).split('/').length - 1].split('?')[0]}
                  </span>
                </div>
            </div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Contractor Addr</div>
            <div className='detail-info-table-body'>{`(${contractorInfo['address']['post_code']}) ${contractorInfo['address']['extra_addr']}, ${contractorInfo['address']['addr']}`}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Contractor Addr</div>
            <div className='detail-info-table-body'>{`(${contractorInfo['address']['post_code']}) ${contractorInfo['address']['n_extra_addr']}, ${contractorInfo['address']['n_addr']}`}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Company Name</div>
            <div className='detail-info-table-body'>{jobInfo.company_name}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Company Industry</div>
            <div className='detail-info-table-body'>{jobInfo.industry}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Company Job Rank</div>
            <div className='detail-info-table-body'>{jobInfo.job_rank}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Main duties at the company</div>
            <div className='detail-info-table-body'>{jobInfo.main_duties}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Company Addr</div>
            <div className='detail-info-table-body'>{`(${jobInfo['office_postcode']}) ${jobInfo['office_extra_addr']}, ${jobInfo['office_addr']}`}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Company Addr</div>
            <div className='detail-info-table-body'>{`(${jobInfo['office_postcode']}) ${jobInfo['office_n_extra_addr']}, ${jobInfo['office_n_addr']}`}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Liquid Assets</div>
            <div className='detail-info-table-body'>{Number(assetInfo.liquid_assets).toLocaleString() + ' USD'}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Monthly Income</div>
            <div className='detail-info-table-body'>{Number(assetInfo.monthly_income).toLocaleString() + ' USD'}</div>
          </div>
          <div className='detail-info-table-row last-item'>
            <div className='detail-info-table-head'>Monthly Expenses</div>
            <div className='detail-info-table-body'>{Number(assetInfo.monthly_expenses).toLocaleString() + ' USD'}</div>
          </div>
        </div>
        <div className='detail-info-table'>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Beneficiary Name</div>
            <div className='detail-info-table-body'>{beneInfo.name}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Beneficiary Relation</div>
            <div className='detail-info-table-body'>{beneInfo.relation}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Beneficiary Birth</div>
            <div className='detail-info-table-body'>{beneInfo.birth.substring(0, 4) + '-' + beneInfo.birth.substring(4, 6) + '-' + beneInfo.birth.substring(6, 8)}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Beneficiary Passport No</div>
            <div className='detail-info-table-body'>{dv(beneInfo.passport_no)}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Beneficiary Phone No</div>
            <div className='detail-info-table-body'>{beneInfo.phone_number}</div>
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Beneficiary Address</div>
            <div className='detail-info-table-body'>{`(${beneInfo['address']['post_code']}) ${beneInfo['address']['extra_addr']}, ${beneInfo['address']['addr']}`}</div>
          </div>
          <div className='detail-info-table-row last-item'>
            <div className='detail-info-table-head'>Beneficiary Address</div>
            <div className='detail-info-table-body'>{`(${beneInfo['address']['post_code']}) ${beneInfo['address']['n_extra_addr']}, ${beneInfo['address']['n_addr']}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TaskDetailDocuments = ({caseData}) => { 
  const getFilename = (url) => {
    return  decodeURIComponent(url).split('/')[decodeURIComponent(url).split('/').length - 1].split('?')[0];
  }
  
  return (
    <div className='detail-info'>
      <div className='detail-info-title'>Documents</div>
      <div className='detail-info-tables-row'>
        <div className='detail-info-table'>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Proposal</div>
            {
              caseData.proposal_info.file ? (
                <div style={{padding: '8px 0px'}}>
                  <div className='detail-info-table-body-download' onClick={() => window.open(caseData.proposal_info.file)}>
                    <img src={'/icon/download.png'}  alt="download" />
                    <span>
                      {getFilename(caseData.proposal_info.file)}
                    </span>
                  </div>
                </div>
              ) : undefined
            }
          </div>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Application</div>
            {
              caseData.application_info.files ? (
                <div style={{padding: '8px 0px'}}>
                  {
                    caseData.application_info.files.map((f, i) => {
                      return (<div className='detail-info-table-body-download' onClick={() => window.open(caseData.application_info.files[i])} key={String(i)}>
                        <img src={'/icon/download.png'} alt="download" />
                        <span>
                          {getFilename(caseData.application_info.files[i])}
                        </span>
                      </div>)
                    })
                  }
                </div>
              ) : undefined
            }
          </div>
          <div className='detail-info-table-row last-item'>
            <div className='detail-info-table-head' style={{minHeight: '38px'}}>Policy</div>
            {
              caseData.policy_info.file ? (
                <div className='detail-info-table-body-download'>
                  <img src={'/icon/download.png'} alt="download" />
                  <span>
                    HONG_GILDONG_Proposal.pdf
                  </span>
                </div>
              ) : undefined
            }
          </div>
        </div>
        <div className='detail-info-table-col-half'>
        </div>
      </div>
    </div>
  );
};

const TaskDetailHistory = ({caseData}) => {
  const [histories, setHistories] = useState([]);

  useEffect(() => {
    req();
  }, []);

  const req = async () => {
    const db = getFirestore(getApp());
    const d = await getDoc(doc(db, 'insurance_cases', caseData.case_no, 'histories', 'histories'));
    setHistories(d.data().histories);
  };

  return (
    <div className='detail-info'>
      <div className='detail-info-title'>History</div>
      <div className='detail-info-half-row'>
        <div className='detail-info-table-col-half border'>
          {
            histories.map((v, i) => {
              return (
                <div className={'detail-info-table-history' + (i === histories.length - 1 ? ' last' : '')} key={String(i)}>
                  <div className='detail-info-table-history-text'>
                    {getListStepLabel(v['step'], v['substep'])}
                  </div>
                  <div className='detail-info-table-history-date'>{new Date(v.timestamp).toLocaleDateString() + ' ' + new Date(v.timestamp).toLocaleTimeString()}</div>
                </div>
              );
            })
          }
        </div>
        <div className='detail-info-table-col-half'>
        </div>
      </div>
    </div>
  );
};

const TaskDetailConsultant = ({openGradeModal, caseData}) => {
  const navigate = useNavigate();
  return (
    <div className='detail-info'>
      <div className='detail-info-title'>Consultant Info</div>
      <div className='detail-info-tables-row'>
        <div className='detail-info-table'>
          <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Name</div>
            <div className='detail-info-table-body-between'>
              <span>
                {caseData.consultant_info.name}
              </span>
              <div className='detail-info-table-btn' onClick={() => navigate(`/consultants?userId=${caseData.consultant_info.id}`)}>
                <img src={'/icon/salesManage.png'} />
                <span>Go to Detail</span>
              </div>
            </div>
          </div>
          <div className='detail-info-table-row last-item'>
            <div className='detail-info-table-head'>Phone</div>
            <div className='detail-info-table-body-img'>
              <span>
              {splitPhoneNumber(caseData.consultant_info.phone_number)}
              </span>
            </div>
          </div>
          {/* <div className='detail-info-table-row'>
            <div className='detail-info-table-head'>Email</div>
            <div className='detail-info-table-body'>{caseData.consultant_info.email}</div>
          </div> */}
          {/* <div className='detail-info-table-row last-item'>
            <div className='detail-info-table-head'>Grade</div>
            <div className='detail-info-table-body-between'>
              <div className='detail-info-table-body-img-between'>
                <img src={'/icon/yellow.png'} />
                <span>
                </span>
              </div>
            </div>
          </div> */}
        </div>
        <div className='detail-info-table-col-half'>
        </div>
      </div>
    </div>
  );
};

export default TaskDetailComp;