import { useState } from "react";
import Calendar from "react-calendar";
import { returnMonth } from "../../utils/function/function";
import VisitorTable from "../../components/table/visitor_table";
import Toast from "../../components/common/toast";

  const reserved = {year: 2025, month: 0, date: 20, amount: 2};
  const canReserve = {year: 2025, month: 0, date: 20, amount: 2};

const VisitSchedule = () => {
  const [loading, setIsLoading] = useState(true);
  const [toast, setToast] = useState('');
  const [visitAvailable, setVisitAvailable] = useState(false);

  const today = new Date();
  const [selected, setSelected] = useState({year: today.getFullYear(), month: today.getMonth(), date: today.getDate()});

  const dayClick = (value) => {
    setSelected({year: value.getFullYear(), month: value.getMonth(), date: value.getDate()});
  };

  const visitAvailableClick = () => {
    if (visitAvailable) {
      setVisitAvailable(false);
      setToast('visitDisabled');
    } else {
      setVisitAvailable(true);
      setToast('visitAvailable');
    }
  };

  return (
    <div className="schedule-container">
      <Toast
        text={'Visit availability disabled for this date.'}
        start={toast}
        setStart={setToast}
        toastName="visitDisabled"
      />
      <Toast
        text={'This date is now available for visits.'}
        start={toast}
        setStart={setToast}
        toastName="visitAvailable"
      />
      <Calendar
        tileClassName={
          ({ activeStartDate, date, view }) =>
            view === 'month' &&
            date.getDate() === selected.date &&
            date.getMonth() === selected.month &&
            date.getFullYear() === selected.year ? 'selected' : null
        }
        tileContent={
          ({ activeStartDate, date, view }) =>
            view === 'month' &&
            date.getDate() === canReserve.date &&
            date.getMonth() === canReserve.month &&
            date.getFullYear() === canReserve.year &&
            date.getDate() === reserved.date &&
            date.getMonth() === reserved.month &&
            date.getFullYear() === reserved.year ? <div className="schedule-amount">{reserved.amount}<img src={'/icon/canReserve.png'} className="schedule-canReserve" /></div> :
            view === 'month' &&
            date.getDate() === reserved.date &&
            date.getMonth() === reserved.month &&
            date.getFullYear() === reserved.year ? <div className="schedule-amount">{reserved.amount}</div> : 
            view === 'month' &&
            date.getDate() === canReserve.date &&
            date.getMonth() === canReserve.month &&
            date.getFullYear() === canReserve.year ? <img src={'/icon/canReserve.png'} className="schedule-canReserve" /> : null
        }
        calendarType='gregory'
        locale='en'
        view='month'
        prevLabel={<img className='img' src={'/icon/arrow-cal-prev.png'} />}
        nextLabel={<img className='img' src={'/icon/arrow-cal-next.png'} />}
        prev2Label={<img className='img' src={'/icon/arrow2x-cal-prev.png'} />}
        next2Label={<img className='img' src={'/icon/arrow2x-cal-next.png'} />}
        onClickDay={(value, event) => dayClick(value)}
      />
      <div className="schedule-detail-container">
        <div className="schedule-visit-top">
          <div className="schedule-visit-date">{returnMonth(selected.month + 1)}. {selected.date}. {selected.year}</div>
          <div className="schedule-visit-btn" onClick={() => visitAvailableClick()}>
            {
              visitAvailable ?
              <img src={'/icon/checkbox-green.png'} /> :
              <img src={'/icon/checkbox.png'} />
            }
            <div>Visit Available</div>
          </div>
        </div>
        <div className="schedule-visit-table-wrap">
          <div className="schedule-visit-title">Visitor Information</div>
          <VisitorTable lists={['a', 'b']} />
        </div>
        <div className="schedule-visit-table-wrap">
          <div className="schedule-visit-title">Note</div>
          <textarea className="schedule-visit-textarea" placeholder="Enter a note for this date..." />
        </div>
      </div>
    </div>
  );
};

export default VisitSchedule;
