import { getApp } from "firebase/app";
import { doc, getFirestore, updateDoc, arrayUnion } from "firebase/firestore";

const uploadApplication = async (caseNo, files, invoiceInfo) => {
  const v = {
    step_info: {
      step: 2,
      sub_step: 'REC_APPLICATION',
    },
    application_info: {
      files,
    },
    last_updated_at: new Date().getTime(),
    last_updated_at_str: new Date(),
  };

  if (invoiceInfo.premiumV) {
    v.invoices = [{
      case_opened: true,
      case_submitted: false,
      created_at: new Date().getTime(),
      files: [],
      index: 1,
      levy: {
        usd: Number(invoiceInfo.levyV),
        hkd: Number(invoiceInfo.levyV) * Number(invoiceInfo.hkdM)
      },
      premium: {
        exp: invoiceInfo.premiumLabel,
        usd: Number(invoiceInfo.premiumV),
        hkd: Number(invoiceInfo.premiumV) * Number(invoiceInfo.hkdM)
      },
      transfer_fee: {
        usd: Number(invoiceInfo.transferFeeV),
        hkd: Number(invoiceInfo.transferFeeV) * Number(invoiceInfo.hkdM)
      },
      due_date: invoiceInfo.dueDate,
    }];
  }

  await Promise.all([
    updateDoc(doc(getFirestore(getApp()), 'insurance_cases', caseNo), v),
    updateDoc(doc(getFirestore(getApp()), 'insurance_cases', caseNo, 'histories', 'histories'), {
      histories: arrayUnion({
        step: 2,
        substep: 'REC_APPLICATION',
        timestamp: new Date().getTime(),
      }),
    })
  ]);
};

export default uploadApplication;
