import { getApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const SideNav = ({isMaster}) => {
  const navigate = useNavigate();

  const logout = () => {
    signOut(getAuth(getApp()));
  }

  const [selectedNav, setSelectedNav] = useState('');
  useEffect(() => {
    checkNav();
  }, []);

  const checkNav = () => {
    const href = window.location.href;
    if (href.includes('/tasks')) {
      setSelectedNav('tasks');
    } else if (href.includes('/consultants')) {
      setSelectedNav('consultants');
    } else if (window.location.pathname === '/') {
      setSelectedNav('tasks');
    } else {
      setSelectedNav('');
    }
  }

  const clickNav = (target) => {
    navigate('/' + target);
    checkNav();
  };

  return (
    <div className="sidenav-container">
      <div>
        <div className="sidenav-logo-wrapper" onClick={() => clickNav('')}>
          <img src="/img/home-appbar-logo.avif" alt="home" />
        </div>
        <div className="sidenav-items-container">
          <div className={`link ${selectedNav === 'tasks' ? 'active' : ''}`} onClick={() => clickNav('tasks')}>
            <img alt='task' src="/icon/works.png" />
            Task
          </div>
          {
            isMaster ? (
              <>
                <div className={`link ${selectedNav === 'consultants' ? 'active' : ''}`} onClick={() => clickNav('consultants')}>
                  <img alt='task' src="/icon/salesProfile.png" />
                  Consultant
                </div>
                {/* <div className={`link ${selectedNav === 'schedule' ? 'active' : ''}`} onClick={() => clickNav('visit_schedule')}>
                  <img alt='task' src="/icon/calendar.png" />
                  Visit Schedule
                </div> */}
                {/* <div className={`link ${selectedNav === 'schedule' ? 'active' : ''}`} onClick={() => clickNav('schedule')}>
                  <img alt='task' src="/icon/calendar.png" />
                  Visit Schedule
                </div> */}
              </>
            ) : null
          }
        </div>
      </div>
      <div className="logout" onClick={logout}>
        <img alt='task' src="/icon/power.png" />
        Log Out
      </div>
    </div>
  );
};

export default SideNav;
