import { useNavigate } from "react-router-dom";

const VisitorTable = ({lists}) => {
  const navigate = useNavigate();

  return (
    <div className="table-col visitor">
      <div className="table-header">
        <div className="visitor-table-head-row col1">Customer</div>
        <div className="visitor-table-head-row col2">Customer Mobile</div>
        <div className="visitor-table-head-row col3">Consultant</div>
        <div className="visitor-table-head-row col4">Introducer Mobile</div>
        {/* <div className="consultant-base col8">Contact</div> */}
      </div>
      <div className="table-content-consultant">
        {
          lists.length === 0 ? (
            <div className="empty-wrapper">
              <img alt="empty" src="/icon/emptytable.avif" className="empty" />
              <div>There are no consultants registered yet.</div>
            </div>
          ) : <div />
        }
        {
          lists.map((v) => {
            return (
              <div className="table-item visitor">
                <div className="visitor-table-body-row col1 img"><img src={'/icon/flag-kr.png'} />{'홍길동'}</div>
                <div className="visitor-table-body-row col2">{'+82 10 0000 0000'}</div>
                <div className="visitor-table-body-row col3 img"><img src={'/icon/flag-kr.png'} />{'이순신'}</div>
                <div className="visitor-table-body-row col4">{'+82 10 0000 0000'}</div>
                {/* <div className="consultant-base col8">+82 10 6207 7569</div> */}
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

export default VisitorTable;
